export default {
  props: {
    height: {
      type: String,
      default: '600rpx',
      required: false
    },
    goodItem: {
      type: Object,
      default: () => { },
      required: false
    },
    shopId: {
      type: String,
      default: '',
      required: false
    }
  }
}
