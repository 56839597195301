<template>
  <div>
    <el-dialog title="系统菜品库" :visible.sync="dialogVisible" >
      <div
        class="dialog__body"
        v-infinite-scroll="getList"
        :infinite-scroll-immediate="false"
        v-loading="loading"
      >
       <foodBankItem :active='foodBankItemData.id===item.id' @click.native="setFoodBankItem(item)" v-for="item in list" :key="item.id" :foodBankItem='item'/>

      </div>
      <span slot="footer" class="dialog-footer" v-show="foodBankItemData">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateData"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import foodBank from '@/service/foodBank.js'
import foodBankItem from '../foodBankItem/index.vue'
import { mapState } from 'vuex'
export default {
  props: {
    show: {
      type: Boolean,
      default: true
    }
  },
  components: {
     foodBankItem
  },
  data () {
    return {
      foodBankItemId: '',
      dialogVisible: false,
      finished: false, // 是否加载完成，
      loading: false, // 是否加载中,
      list: [], // 桌台数据
      status: 'loadmore',
      isEmptyTable: false,
      businessTableId: '',
      foodBankItemData: '',
      pageData: {
        pageNum: 1,
        pageSize: 20
      }
    }
  },
  computed: {
    ...mapState({
      shopInfo: (store) => store.login.shopInfo
    })
  },
  watch: {
    dialogVisible () {
      this.$emit('update:show', this.dialogVisible)
    },
    show: {
      handler () {
        this.dialogVisible = this.show
        console.log(this.show)
        if (this.show) {
          this.finished = false // 是否加载完成，
          this.loading = false // 是否加载中,
          this.list = [] // 桌台数据
          this.status = 'loadmore'
          this.pageData = {
            pageNum: 1,
            pageSize: 20
          }
          this.getList()
        }
      },
      immediate: true
    }
  },
  methods: {
    setFoodBankItem (item) {
         this.foodBankItemData = item
    },
    updateData () {
       this.dialogVisible = false
       this.$emit('setFoodBankItem', this.foodBankItemData)
    },
    async getList () {
      if (this.finished || this.loading) {
        return
      }
      const data = {
        ...this.pageData,
        businessId: this.shopInfo.id,
        keyword: this.keyword
      }
      this.loading = true
      this.status = 'loading'
      const res = await foodBank.foodBankPage(data)
      this.list = [...this.list, ...res.data.list]
      if (this.list.length >= res.data.total) {
        this.finished = true
        this.status = 'nomore'
      } else {
        this.status = 'loadmore'
      }
      this.pageData.pageNum = this.pageData.pageNum + 1
      this.loading = false
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog{
  width: 930px !important;
}
.dialog {
  &__body {
    height: 500px;
    overflow-y: auto;
  }
}
</style>
