import request from '@/utils/request.js'
export default {
  // 分页查询
  foodBankPage: (data) => {
    return request({
      url: '/sys/foodBank/page',
      data,
      method: 'post'
    })
  }

}
