var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    foodItem: true,
  }},[_c('div',{staticClass:"foodItem__img"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":_vm.imgUrl + _vm.goodItemObj.foodImgUrl,"fit":"fit"}})]),_c('div',{staticClass:"foodItem__data"},[_c('div',{staticClass:"foodItem__data--name ellipsis"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.goodItemObj.foodName,"placement":"right-end"}},[_c('div',{staticClass:"foodItem__data--name ellipsis"},[_vm._v(" "+_vm._s(_vm.goodItemObj.foodName)+" ")])])],1),_c('div',{staticClass:"foodItem__data--money"},[_vm._v(" ￥"+_vm._s(_vm.goodItemObj.foodConcessionalPrice)+" ")])]),(_vm.showBuy)?_c('div',{staticClass:"foodItem__bottom"},[(
        _vm.goodItemObj.foodProp === null ||
        (_vm.goodItemObj.foodProp && _vm.goodItemObj.foodProp.length === 0)
      )?_c('div',{staticClass:"foodItem__bottom--buy"},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.foodValue !== 0),expression:"foodValue !== 0"}],staticClass:"el-icon-remove",on:{"click":_vm.reduce}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.foodValue !== 0),expression:"foodValue !== 0"}],staticClass:"foodValue"},[_vm._v(_vm._s(_vm.foodValue))]),_c('i',{staticClass:"el-icon-circle-plus",on:{"click":_vm.setCarAnmation}})]):_vm._e(),_c('el-popover',{attrs:{"placement":"right-end","trigger":"click"}},[_c('div',[_c('foodProp',{attrs:{"goodItem":_vm.goodItemObj}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.goodItemObj.foodProp !== null &&
          _vm.goodItemObj.foodProp &&
          _vm.goodItemObj.foodProp.length !== 0
        ),expression:"\n          goodItemObj.foodProp !== null &&\n          goodItemObj.foodProp &&\n          goodItemObj.foodProp.length !== 0\n        "}],staticClass:"foodItem__bottom--select",attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" 选规格 ")])])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }