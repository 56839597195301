<template>
  <div :class="{
        'foodBankItem':true,
         'foodBankItem__active':active
  }">
    <div class="foodBankItem__img">
      <el-image
        style="width: 100%; height: 100%"
        :src="imgUrl + foodBankItem.foodImgUrl"
        fit="fit"
      ></el-image>
    </div>
    <div class="foodBankItem__name">
      {{ foodBankItem.foodName }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    foodBankItem: {
      type: Object,
      default: () => {}
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.foodBankItem {
  width: 186px;
  height: 231px;
  background: #f7f7fc;
  border-radius: 10px;
  border: 1px solid #fff;
  float: left;
  margin-right: 25px;
  margin-bottom: 25px;
  border-radius: 10px;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
  &__active{
    border: 1px solid #ffbb12;
    box-sizing: border-box;
  }
  &__img {
    width: 100%;
    height: 186px;
  }
  &__name {
    padding-left: 9px;
    padding-top: 10px;
    box-sizing: border-box;
    color: #000000;
    font-weight: 400;
    font-size: 16px;
  }
}
</style>
