<template>
  <div class="foodGm" v-loading="loading">
    <div class="foodGm__left">
      <div class="foodGm__left--head">
        <el-input
          v-model="keyword"
          placeholder="请输入关键词"
          @change="getFoodLs"
        ></el-input>
        <el-tabs v-model="activeName" @tab-click="tabClick">
          <el-tab-pane
            :label="item.foodTypeName"
            :name="item.id"
            :key="item.id"
            v-for="item in areaLs"
          ></el-tab-pane>
        </el-tabs>
      </div>
      <div class="foodGm__left--body">
        <el-scrollbar style="height: 100%" ref="scrollbar">
          <div
            class="foodGm__left--body__item"
            v-for="item in list"
            :key="item.foodType.id+Date.now()"
            :id="item.foodType.id"
          >
            <div class="foodGm__left--body__item--title">
              {{ item.foodType.foodTypeName }}
            </div>
            <div>
              <foodItem
                :showBuy="false"
                v-for="foodItem in item.foodInfoList"
                :key="foodItem.id"
                :goodItem="foodItem"
                @click.native="goodsId = foodItem.id"
              />
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
    <div class="foodGm__right">
      <el-scrollbar style="height: 100%">
        <foodSeting
          :foodTypeColumns="areaLs"
          @update="setUpdate"
          :goodsId="goodsId"
        />
      </el-scrollbar>
    </div>
  </div>
</template>
<script>
import foodSeting from '@/components/foodSeting/index.vue'
import mealApp from '@/service/meal-app.js'
import foodItem from '@/views/shop/components/foodItem/index.vue'
// import uniPay from '@/utils/pay.js'
import { mapState } from 'vuex'
export default {
  components: {
    foodSeting,
    foodItem
  },
  data () {
    return {
      goodsId: '',
      keyword: '',
      activeName: '',
      areaLs: [], // 区域列表
      loading: false, // 是否加载中,
      list: [], // 桌台数据
      status: false,
      isEmptyTable: false,
      businessTableId: ''
    }
  },
  computed: {
    ...mapState({
      shopInfo: (store) => store.login.shopInfo
    })
  },
  mounted () {
     this.getFoodLs()
    this.$refs.scrollbar.wrap.onscroll = (e) => {
      if (this.status) {
        return
      }
      const ls = [...this.list]
      ls.forEach((item) => {
        const dom = document.getElementById(item.foodType.id)
        const domTop = dom.offsetTop
        const domBom = dom.offsetHeight + dom.offsetTop
        if (domBom >= e.target.scrollTop && e.target.scrollTop >= domTop) {
          this.activeName = item.foodType.id
        }
      })
    }
  },
  methods: {
    // 数据更新
    setUpdate () {
      this.getFoodLs()
      this.goodsId = ''
    },
    // 获取商品列表
    async getFoodLs () {
      this.loading = true
      const res = await mealApp.listFoodInfoVo({
        businessId: this.shopInfo.id,
        keyword: this.keyword
      })
      this.areaLs = res.data.map(item => item.foodType)
      this.activeName = this.areaLs.length > 0 ? this.areaLs[0].id : ''
      this.list = res.data
      this.loading = false
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
    tabClick () {
      this.status = true
      setTimeout(() => {
        this.status = false
      }, 1000)
      const dom = document.getElementById(this.activeName)
      dom.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.foodGm {
  height: 100%;
  display: flex;
  &__left {
    height: 100%;
    background: #ffffff;
    border-radius: 24px;
    flex: 1;
    min-width: 0px;
    display: flex;
    flex-direction: column;
    transition: all 0.3s;
    &--head {
      padding: 0px 20px;
      padding-top: 24px;
      box-sizing: border-box;
      ::v-deep .el-input__inner {
        background-color: #f7f7fc;
        border-radius: 10px !important;
        height: 52px !important;
        text-align: center;
      }
      ::v-deep .el-tabs__item {
        height: 87px !important;
        line-height: 87px !important;
        color: #000000;
        font-weight: 700;
        font-size: 20px;
        width: 174px;
        text-align: center;
        padding: 0px !important;
      }
      ::v-deep .el-tabs__active-bar {
        width: 174px !important;
        background-color: #ffbb12 !important;
      }
    }
    &--body {
      flex: 1;
      min-height: 0px;
      width: 100%;
      overflow: auto;
      padding: 30px;
      padding-top: 10px;
      box-sizing: border-box;
      &__item {
        &--title {
          color: #000000;
          font-weight: 900;
          font-size: 24px;
          margin-bottom: 23px;
        }
      }
    }
  }
  &__right {
    background-color: #fff;
    width: 671px;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
    margin-left: 56px;
  }
}
.test {
  height: 300px;
}
.test:nth-child(2n) {
  background-color: red;
}
::v-deep .el-scrollbar {
  overflow-y: hidden !important;
}
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
  overflow-y: auto !important;
}
::v-deep .is-horizontal {
  display: none;
}
#dlKeyword {
  position: absolute;
  z-index: -999;
  opacity: 0;
  width: 0px;
  height: 0px;
}
::v-deep .el-tabs__nav-next{
  line-height: 87px !important;
}
::v-deep .el-tabs__nav-prev{
  line-height: 87px !important;
}
</style>
