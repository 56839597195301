<template>
  <div class="foodSeting" v-loading="loading">
    <div class="foodSeting__head">
      <div class="foodSeting__head--title">菜品编辑</div>
      <div class="foodSeting__head--buttonLs">
        <div
          class="foodSeting__head--buttonLs__left"
          @click="foodBankShow = true"
        >
          <i class="el-icon-notebook-1"></i>
          <span>食品库</span>
        </div>
        <div
          class="foodSeting__head--buttonLs__right"
          v-if="formData.id"
          @click="delGoods"
        >
          <i class="el-icon-delete-solid"></i>
          <span>删除</span>
        </div>
      </div>
    </div>
    <!-- 表单的开始 -->
    <el-form
      :model="formData"
      :rules="rules"
      ref="form"
      size="small"
      label-position="left"
      label-width="80px"
    >
      <el-form-item label="图片：" prop="foodImgUrl">
        <el-upload
          accept="image/*"
          class="avatar-uploader"
          :headers="headers"
          :action="host + '/auth/upload/file'"
          :show-file-list="false"
          :on-success="uploadSuccess"
          :before-upload="beforeUpload"
        >
          <img
            v-if="formData.foodImgUrl"
            :src="imgUrl + formData.foodImgUrl"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <div class="el-upload__tip" slot="tip">
            只能上传图片文件，且不超过1M
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item label="标题：" prop="foodName">
        <el-input
          v-model="formData.foodName"
          placeholder="请输入标题"
        ></el-input>
      </el-form-item>
      <el-form-item label="描述：" prop="remarks">
        <el-input
          v-model="formData.remarks"
          placeholder="请输入描述"
        ></el-input>
      </el-form-item>
      <el-form-item label="规格：">
        <div class="foodSeting__from--specification">
          <el-form-item
            label="原价"
            prop="foodOriginalPrice"
            label-width="120px"
          >
            <el-input v-model="formData.foodOriginalPrice"></el-input>
          </el-form-item>
          <el-form-item
            label="优惠价"
            prop="foodConcessionalPrice"
            label-width="120px"
          >
            <el-input v-model="formData.foodConcessionalPrice"></el-input>
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label="属性：" class="fromPorp">
        <div
          class="foodSeting__from--porp"
          v-for="(item, index) in formData.foodPropDtoList"
          :key="index + 'foodPropDtoList'"
        >
          <div class="foodSeting__from--porp__left">
            <i class="el-icon-error" @click="dalFoodPropDto(index)"></i>
            <el-form-item
              :prop="`foodPropDtoList[${index}].name`"
              label="属性名称"
              :rules="[
                {
                  required: true,
                  message: '属性名称不能为空',
                  trigger: 'change',
                },
              ]"
              label-width="120px"
            >
              <el-input
                v-model="formData.foodPropDtoList[index].name"
                placeholder="属性名称"
              ></el-input>
            </el-form-item>
          </div>
          <div class="foodSeting__from--porp__right">
            <div class="foodSeting__from--porp__right--title">属性细分</div>
            <div class="foodSeting__from--porp__right--body">
              <div
                class="foodSeting__from--porp__right--body__item"
                v-for="(specsListItem, specsListIndex) in formData
                  .foodPropDtoList[index].specsList"
                :key="specsListIndex + 'specsListIndex'"
              >
                <i
                  class="el-icon-error"
                  @click="delItemSpecs(index, specsListIndex)"
                ></i>
                <el-form-item
                  :prop="
                    'foodPropDtoList.' +
                    index +
                    '.specsList.' +
                    specsListIndex +
                    '.name'
                  "
                  :rules="[
                    {
                      required: true,
                      message: '请填写',
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-input
                    class="borderRight"
                    v-model="
                      formData.foodPropDtoList[index].specsList[specsListIndex]
                        .name
                    "
                    placeholder="名称"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  :prop="
                    'foodPropDtoList.' +
                    index +
                    '.specsList.' +
                    specsListIndex +
                    '.price'
                  "
                  :rules="priceRules"
                >
                  <el-input
                    class="borderLeft"
                    v-model="
                      formData.foodPropDtoList[index].specsList[specsListIndex]
                        .price
                    "
                    placeholder="价格"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="foodSeting__from--porp__right--body__item addButton">
                <i class="el-icon-plus" @click="addSpecs(index)"></i>
              </div>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label-width="80px">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-plus"
          @click="addFoodPropDtoList"
          >增加属性</el-button
        >
      </el-form-item>
      <el-form-item label="分组：" prop="foodTypeId">
        <div class="foodSeting__from--tag">
          <div
            class="foodSeting__from--tag__left"
            @click="foodTypeLongpress({})"
          >
            新建分组
          </div>
          <div class="foodSeting__from--tag__riht">
            <el-tag
              :key="tag.id"
              v-for="tag in foodTypeColumns"
              closable
              :type="formData.foodTypeId === tag.id ? 'success' : 'info'"
              :disable-transitions="false"
              @click.native="formData.foodTypeId = tag.id"
              @close="handleClose(tag.id)"
              @dblclick.native="foodTypeLongpress(tag)"
            >
              {{ tag.foodTypeName }}
            </el-tag>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="区域：" prop="businessAreaId">
        <div class="foodSeting__from--tag">
          <div
            class="foodSeting__from--tag__left"
            @click="businessTableAreaLongpress({})"
          >
            新建区域
          </div>
          <div class="foodSeting__from--tag__riht">
            <el-tag
              :key="item.id"
              v-for="item in areaLs"
              closable
              :type="formData.businessAreaId === item.id ? 'success' : 'info'"
              :disable-transitions="false"
              @click.native="formData.businessAreaId = item.id"
              @close="businessTableAreaClose(item.id)"
              @dblclick.native="businessTableAreaLongpress(item)"
            >
              {{ item.businessAreaName }}
            </el-tag>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <div class="foodSeting__button">
      <div class="foodSeting__button--complete" @click="sava">完成</div>
    </div>
    <food-bank-dialog
      :show.sync="foodBankShow"
      @setFoodBankItem="setFoodBankItem"
    />
  </div>
</template>
<script>
import { host } from '@/utils/url.js'
import foodBankDialog from '@/components/foodSeting/components/foodBankDialog/index.vue'
import businessTableArea from '@/service/businessTableArea.js'
import foodTypeApi from '@/service/foodType.js'
// eslint-disable-next-line no-unused-vars
import foodInfoApi from '@/service/foodInfo.js'
import { mapState } from 'vuex'
export default {
  props: {
    foodTypeColumns: {
      type: Array,
      default: () => []
    },
    goodsId: {
      type: String,
      default: ''
    }
  },
  components: {
    foodBankDialog
  },
  computed: {
    ...mapState({
      shopInfo: (store) => store.login.shopInfo
    })
  },
  data () {
    return {
      foodTypeName: '',
      foodBankShow: false,
      inputVisible: false,
      host: host,
      headers: {
        Authorization: window.sessionStorage.getItem('token')
      },
      loading: false,
      areaLs: [],
      formData: {
        foodImgUrl: '',
        foodName: '',
        remarks: '',
        foodOriginalPrice: '',
        foodConcessionalPrice: '',
        stock: -1,
        foodPropDtoList: [],
        foodTypeId: '',
        businessAreaId: '',
        id: ''
      },
      rules: {
        foodImgUrl: [
          { required: true, message: '请上传图片', trigger: 'blur' }
        ],
        foodName: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        foodOriginalPrice: [
          { required: true, message: '请输入原价', trigger: 'blur' },
          { validator: this.priceRulesValidator, trigger: 'change' }
        ],
        foodConcessionalPrice: [
          { required: true, message: '请输入优惠价', trigger: 'blur' },
          { validator: this.priceRulesValidator, trigger: 'change' },
          { validator: this.foodConcessionalPriceValidator, trigger: 'change' }
        ],
        stock: [{ required: true, message: '请输入库存', trigger: 'blur' }],
        foodTypeId: [
          { required: true, message: '请选择分组', trigger: 'blur' }
        ],
        businessAreaId: [
          { required: true, message: '请选择区域', trigger: 'blur' }
        ]
      },
      priceRules: [
        { required: true, message: '请填写', trigger: 'change' },
        { validator: this.priceRulesValidator, trigger: 'change' }
      ]
    }
  },
  watch: {
    goodsId () {
      if (this.goodsId) {
        this.getfoodData(this.goodsId)
      }
    }
  },
  created () {
    this.getAreaLs()
  },
  methods: {
    /**
     * @description 商品详情的回显
     * @param {string} id  商品的id
     */
    async getfoodData (id) {
      const res = await foodInfoApi.foodInfoGetById(id)
      this.formData = { ...res.data }
      // eslint-disable-next-line no-unneeded-ternary
      this.formData.isRecommend = res.data.isRecommend === 1 ? true : false
    },
    /**
     * @description 文件上传成功
     * @param {object} e  上传成功的回调，带有连接
     */
    uploadSuccess (e) {
      this.formData.foodImgUrl = e.data
    },
    foodConcessionalPriceValidator (rule, value, callback) {
      if (
        Number(this.formData.foodOriginalPrice) <
        Number(this.formData.foodConcessionalPrice)
      ) {
        callback(new Error('优惠价不可以大于原价!'))
      }
      callback()
    },
    /**
     * @description 文件上传前
     * @param {file} file  文件对象
     */
    beforeUpload (file) {
      const isLt1M = file.size / 1024 / 1024 < 1
      if (!isLt1M) {
        this.$message.error('上传文件大小不能超过 1MB!')
        return false
      }
    },
    // 获取区域数据
    async getAreaLs () {
       const res = await businessTableArea.businessAreaList({
        businessAreaType: 1,
        businessId: this.shopInfo.id
      })
      this.areaLs = res.data
    },
    // 增加属性
    addFoodPropDtoList () {
      if (this.formData.foodPropDtoList === null) {
        this.formData.foodPropDtoList = []
      }
      this.formData.foodPropDtoList.push({
        name: '',
        specsList: [
          {
            name: '',
            price: ''
          }
        ]
      })
    },
    priceRulesValidator (rule, value, callback) {
      if (isNaN(value)) {
        callback(new Error('请输入数字!'))
      }
      if (Number(value) < 0) {
        callback(new Error('不可少于0!'))
      }
      callback()
    },
    /**
     * @description 删除属性项
     * @param {Number} index  需要删除的索引
     */
    dalFoodPropDto (index) {
      this.formData.foodPropDtoList.splice(index, 1)
    },
    /**
     * @description 删除属性小项
     * @param {Number} index,specsListIndex  需要删除的索引
     */
    delItemSpecs (index, specsListIndex) {
      this.formData.foodPropDtoList[index].specsList.splice(specsListIndex, 1)
    },
    /**
     * @description 增加小项
     * @param {Number} index  需要增加的索引
     */
    addSpecs (index) {
      this.formData.foodPropDtoList[index].specsList.push({
        name: '',
        price: ''
      })
    },
    /**
     * @description 加载菜品库
     * @param {object} item  菜品信息
     */
    setFoodBankItem (item) {
      const formData = {
        ...item
      }
      delete formData.id
      delete formData.sort
      this.formData = {
        ...this.formData,
        ...formData
      }
      this.formData.stock = -1
      this.$refs.form.clearValidate()
    },
    // 点击完成
    sava () {
      this.$refs.form.validate(async (validate) => {
        if (validate) {
          const formData = {
            ...this.formData,
            isRecommend: this.formData.isRecommend ? 1 : 0,
            businessId: this.shopInfo.id,
            stock: -1
          }
          if (
            !this.formData.foodPropDtoList ||
            (this.formData.foodPropDtoList &&
              this.formData.foodPropDtoList.length === 0)
          ) {
            formData.foodProp = null
            formData.foodPropDtoList = null
          }
          const res = await foodInfoApi.foodInfoSave(formData)
          if (res.success) {
            this.$emit('update')
            this.$message({
              message: '操作成功',
              type: 'success'
            })
            this.$refs.form.resetFields()
            this.formData = {
              foodImgUrl: '',
              foodName: '',
              remarks: '',
              foodOriginalPrice: '',
              foodConcessionalPrice: '',
              stock: -1,
              foodPropDtoList: [],
              foodTypeId: '',
              businessAreaId: '',
              id: ''
            }
          }
        }
      })
    },
    // 删除食品类型
    async handleClose (id) {
      const confirm = await this.$confirm('是否删除该食品分类?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (confirm === 'confirm') {
        const res = await foodTypeApi.foodTypeDel(id)
        if (res.success) {
          this.$emit('update')
          this.$message({
            message: '食品分类删除成功',
            type: 'success'
          })
        }
      }
    },
    // 删除区域
    async businessTableAreaClose (id) {
       const confirm = await this.$confirm('是否删除该区域?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (confirm === 'confirm') {
       const res = await businessTableArea.businessTableAreaDel(id)
        if (res.success) {
          this.getAreaLs()
          this.$message({
            message: '区域删除成功',
            type: 'success'
          })
        }
      }
    },
    // 删除菜品
    async delGoods () {
      const confirm = await this.$confirm('是否删除该食品?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (confirm === 'confirm') {
        const res = await foodInfoApi.foodInfoDel(this.formData.id)
        if (res.success) {
          this.$emit('update')
          this.$message({
            message: '食品删除成功',
            type: 'success'
          })
          this.$refs.form.resetFields()
          this.formData = {
            foodImgUrl: '',
            foodName: '',
            remarks: '',
            foodOriginalPrice: '',
            foodConcessionalPrice: '',
            stock: -1,
            foodPropDtoList: [],
            foodTypeId: '',
            businessAreaId: '',
            id: ''
          }
        }
      }
    },
    // 分组长按
    foodTypeLongpress (item) {
      this.$prompt('请输入分组名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: item.foodTypeName,
        inputValidator: (value) => {
          if (value) {
            return true
          }
          return '请输入分组名称'
        }
      }).then(async ({ value }) => {
        const res = await foodTypeApi.foodTypeSave({
          businessId: this.shopInfo.id,
          ...item,
          foodTypeName: value
        })
        if (res.success) {
          this.$emit('update')
        }
      })
    },
    // 修改/新增区域
    businessTableAreaLongpress (item) {
      this.$prompt('请输入区域名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: item.businessAreaName,
        inputValidator: (value) => {
          if (value) {
            return true
          }
          return '请输入分组名称'
        }
      }).then(async ({ value }) => {
        const res = await businessTableArea.businessTableArea({
          sort: this.areaLs.length - 1,
          businessId: this.shopInfo.id,
          ...item,
          businessAreaName: value,
          businessAreaType: 1
        })
        if (res.success) {
          this.getAreaLs()
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.foodSeting {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  padding: 26px 27px;
  box-sizing: border-box;
  &__from {
    &--specification {
      display: flex;
      /deep/ .el-form-item {
        display: flex;
        flex-direction: column;
        margin-right: 30px;
        width: 100px;
      }
      /deep/.el-form-item__content {
        margin-top: 10px;
        margin-left: 0px !important;
      }
      /deep/.el-form-item__label {
        width: 100px !important;
        line-height: normal;
      }
      &__stock {
        flex: 1;
        min-width: 0px;
        .layout {
          display: flex;
          align-content: center;
          height: 40px;
          /deep/.el-input {
            display: flex;
            flex-direction: column;
            margin-right: 30px;
            width: 60px !important;
          }
          /deep/.el-radio-group {
            display: flex;
            align-items: center;
            margin-right: 10px;
          }
        }
      }
    }
    &--porp {
      display: flex;
      &__left {
        position: relative;
        .el-icon-error {
          position: absolute;
          left: 75px;
          top: 3px;
          cursor: pointer;
        }
        /deep/ .el-form-item {
          display: flex;
          flex-direction: column;
          margin-right: 30px;
          width: 100px;
        }
        /deep/.el-form-item__content {
          margin-top: 10px;
          margin-left: 0px !important;
        }
        /deep/.el-form-item__label {
          width: 100px !important;
          line-height: normal;
        }
      }
      &__right {
        /deep/ .el-form-item {
          display: flex;
          flex-direction: column;
          margin-right: 0px;
          width: 60px;
        }
        &--title {
          margin-bottom: 10px;
          font-size: 14px;
          color: #606266;
        }
        &--body {
          &__item {
            float: left;
            margin-right: 10px;
            display: flex;
            border-radius: 4px !important;
            box-sizing: border-box;
            position: relative;
            margin-bottom: 10px;
            .el-icon-error {
              position: absolute;
              right: -5px;
              top: -5px;
              cursor: pointer;
              z-index: 9999;
            }
            /deep/.borderRight .el-input__inner {
              border-top-right-radius: 0px !important;
              border-bottom-right-radius: 0px !important;
            }
            /deep/.borderLeft .el-input__inner {
              border-top-left-radius: 0px !important;
              border-bottom-left-radius: 0px !important;
            }
          }
        }
      }
    }
  }
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 36px;
    &--title {
      color: #000000;
      font-weight: 400;
      font-size: 24px;
    }
    &--buttonLs {
      display: flex;
      margin-right: 17px;
      align-content: center;
      &__left {
        color: #54a5f0;
        font-size: 14px;
        display: flex;
        align-content: center;
        cursor: pointer;
        margin-right: 10px;
        i {
          font-size: 20px;
          margin-right: 10px;
        }
      }
      &__right {
        color: #f05454;
        font-size: 14px;
        cursor: pointer;
        display: flex;
        align-content: center;
        position: relative;
        margin-left: 10px;
        i {
          font-size: 20px;
          margin-right: 10px;
        }
      }
    }
  }
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    &--complete {
      background: #ffbb12;
      border-radius: 8px;
      color: #000000;
      font-weight: 700;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 100px;
      height: 40px;
    }
    &--next {
      background: #ffbb12;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 160px;
      font-weight: 700;
      height: 40px;
    }
  }
}
.foodSeting__head--buttonLs__right::after {
  position: absolute;
  content: "";
  background-color: #e9e9f4;
  width: 1px;
  height: 100%;
  left: -10px;
  top: 0px;
}
/deep/.el-form-item__label {
  min-width: 70px !important;
  line-height: normal;
}
/deep/ .el-form-item__content {
  line-height: normal;
}
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .el-button {
  cursor: pointer;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 81px;
  height: 81px;
  line-height: 81px;
  text-align: center;
}
.avatar {
  width: 81px;
  height: 81px;
  display: block;
}
.fromPorp {
  margin-bottom: 0px;
}
::v-deep .el-scrollbar {
  overflow-y: hidden !important;
}
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
  overflow-y: auto !important;
}
::v-deep .is-horizontal {
  display: none;
}
.addButton {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  .el-icon-plus {
    color: #66b1ff;
    cursor: pointer;
  }
}

::v-deep .el-tag {
  margin-right: 10px;
  cursor: pointer;
  margin-bottom: 10px;
}
.button-new-tag {
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.foodSeting__from--tag {
  display: flex;
  &__left {
    width: 78px;
    height: 27px;
    margin-right: 30px;
    background: #ffffff;
    border: 1px dashed #9f9f9f;
    border-radius: 6px;
    color: #000000;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  &__riht {
    flex: 1;
    min-width: 0px;
  }
}
</style>
