<template>
  <div
    :class="{
      foodItem: true,
    }"
  >
    <div class="foodItem__img">
      <img
        style="width: 100%; height: 100%"
        :src="imgUrl + goodItemObj.foodImgUrl"
        fit="fit"
      />
    </div>
    <div class="foodItem__data">
      <div class="foodItem__data--name ellipsis">
        <el-tooltip
          class="item"
          effect="dark"
          :content="goodItemObj.foodName"
          placement="right-end"
        >
          <div class="foodItem__data--name ellipsis">
            {{ goodItemObj.foodName }}
          </div>
        </el-tooltip>
      </div>
      <div class="foodItem__data--money">
        ￥{{ goodItemObj.foodConcessionalPrice }}
      </div>
    </div>
    <div class="foodItem__bottom" v-if="showBuy">
      <div
        class="foodItem__bottom--buy"
        v-if="
          goodItemObj.foodProp === null ||
          (goodItemObj.foodProp && goodItemObj.foodProp.length === 0)
        "
      >
        <i class="el-icon-remove" v-show="foodValue !== 0" @click="reduce"></i>
        <div class="foodValue" v-show="foodValue !== 0">{{ foodValue }}</div>
        <i class="el-icon-circle-plus" @click="setCarAnmation"></i>
      </div>
      <el-popover placement="right-end" trigger="click">
        <div>
          <foodProp :goodItem="goodItemObj" />
        </div>
        <div
          v-show="
            goodItemObj.foodProp !== null &&
            goodItemObj.foodProp &&
            goodItemObj.foodProp.length !== 0
          "
          slot="reference"
          class="foodItem__bottom--select"
        >
          选规格
        </div>
      </el-popover>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import foodProp from '@/views/shop/components/foodProp/index.vue'
export default {
  components: {
    foodProp
  },
  props: {
    goodItem: {
      type: Object,
      default: () => {}
    },
    showBuy: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      num: 0,
      buyTitle: '',
      goodItemObj: {}
    }
  },
  computed: {
    ...mapState({
      card: (store) => store.buyCard.card,
      shopInfo: (store) => store.login.shopInfo
    }),
    foodValue () {
      if (!this.goodItemObj.foodProp || this.goodItemObj.foodProp.length === 0) {
        const listItem = this.card.find(
          (item) => item.phone === 'admin'
        )

        const item = listItem ? listItem.ls.find((item) => item.id === this.goodItemObj.id) : null

        if (item) {
          return item.count
        } else {
          return 0
        }
      }
      return 0
    },
    foodValueProp () {
      const listItem = this.card.find((item) => item.phone === 'admin')
      if (listItem) {
        return listItem.ls.reduce((pre, item) => {
          if (item.id === this.goodItemObj.id) {
            return pre + item.count
          }
          return pre
        }, 0)
      }
      return 0
    }
  },
  mounted () {
    this.goodItemObj = { ...this.goodItem }
    if (this.goodItem.foodProp) {
      this.goodItemObj.foodProp = JSON.parse(this.goodItem.foodProp)
    }
  },
  methods: {
    ...mapMutations({
      setCard: 'buyCard/setCard',
      reduceCard: 'buyCard/reduceCard'
    }),
    chenckFoodProp () {},
    setCarAnmation (e) {
      // 点击加菜
      this.setCard({
        shopId: this.shopInfo.id,
        ...this.goodItemObj
      })
    },
    // 减少购物车
    reduce () {
      this.reduceCard({
        shopId: this.shopInfo.id,
        ...this.goodItemObj
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.foodItem {
  display: inline-block;
  width: 186px;
  background: #f7f7fc;
  border-radius: 10px;
  overflow: hidden;
  margin-left: 35px;
  margin-bottom: 24px;
  padding-bottom: 10px;
  cursor: pointer;
  &__img {
    width: 186px;
    height: 168px;
  }
  &__data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 9px;
    box-sizing: border-box;
    &--name {
      color: #000000;
      font-size: 16px;
    }
    &--money {
      color: #f05454;
      font-size: 14px;
    }
  }
  &__bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 21px;
    padding-right: 9px;
    box-sizing: border-box;
    &--buy {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      .foodValue {
        margin-left: 20px;
        margin-right: 20px;
        font-size: 16px;
      }
      i {
        color: #ffbb12;
        font-size: 20px;
        cursor: pointer;
      }
    }
    &--select {
      cursor: pointer;
      background-color: #ffbb12;
      width: 50px;
      height: 20px;
      display: flex;
      align-items: center;
      font-size: 5px;
      font-weight: 700;
      justify-content: center;
      border-radius: 5px;
    }
  }
}
.foodItem:nth-child(4n-3) {
  margin-left: 0px;
}
::v-deep .el-popover {
  padding: 0px !important;
  width: 400px !important;
}
.buyTime {
  filter: grayscale(100%);
}
</style>
