<template>
  <div class="foodProp">
    <div class="foodProp__title">
      {{ goodItem.foodName }}
    </div>
    <div class="foodProp__body">
      <el-scrollbar style="height: 100%" ref="scrollbar">
        <div
          class="foodProp__body--item"
          v-for="(item, index) in goodItem.foodProp"
          :key="index"
        >
          <div class="foodProp__body--item__title">
            {{ item.name }}
          </div>
          <div class="foodProp__body--item__list">
            <div
              @click="setspecsItem(item, specsItem)"
              v-for="(specsItem, index1) in item.specsList"
              :key="index1"
              :class="{
                'foodProp__body--item__list--item': true,
                'foodProp__body--item__list--item__active':
                  foodPropObj[item.name].name === specsItem.name,
              }"
            >
              <div class="foodProp__body--item__list--item__name">
                {{ specsItem.name }}
              </div>
              <div class="foodProp__body--item__list--item__money" v-if="specsItem.price>0">
                {{ specsItem.price }}
              </div>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>
    <div class="foodProp__bottom">
      <div class="foodProp__bottom--top">
        <div class="foodProp__bottom--top__moeny">￥{{ getChenckMoney }}</div>
        <div
          class="foodProp__bottom--top__button"
          @click="setCarAnmation"
          v-if="carVal === 0"
        >
          加入购物车
        </div>
        <div class="foodItem__bottom--buy" v-else>
          <i class="el-icon-remove" @click="reduce"></i>
          <div class="foodValue">{{ carVal }}</div>
          <i class="el-icon-circle-plus" @click="setCarAnmation"></i>
        </div>
      </div>
      <div class="foodProp__bottom--prop">已选规格:{{ getProp }}</div>
    </div>
  </div>
</template>
<script>
/**
 * uni-goods-foodProp 规格选择
 * @description 规格选择
 * @property {Boolean} show 是否显示
 * @property {String} height 高度
 * @property {Object} goodItem 数据
 * */
import props from './props.js'
import { mapMutations, mapState } from 'vuex'
import Decimal from 'decimal.js'
export default {
  mixins: [props],
  data () {
    return {
      foodPropObj: {},
      value: 0
    }
  },
  watch: {
    goodItem: {
      handler () {
        if (Object.keys(this.goodItem).length > 0) {
          const obj = {}
          this.goodItem.foodProp &&
            this.goodItem.foodProp.forEach((item) => {
              // 默认选中第一项
              obj[item.name] = item.specsList[0]
            })
          this.foodPropObj = obj
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapState({
      card: (store) => store.buyCard.card,
      shopInfo: (store) => store.login.shopInfo
    }),
    carVal () {
      const str = JSON.stringify(this.foodPropObj)

      const listItem = this.card.find((item) => item.phone === 'admin')

      const item = listItem ? listItem.ls.find((item) => item.id === this.goodItem.id && str === item.foodProp) : null
      // 获取当前商品

      if (item) {
        return item.count
      } else {
        return 0
      }
    },
    // 计算选择的套餐价格
    getChenckMoney () {
      let money = 0
      if (Object.keys(this.foodPropObj).length === 0) {
        return Number(
          this.goodItem.foodConcessionalPrice
            ? new Decimal(this.goodItem.foodConcessionalPrice)
            : 0
        )
      }
      for (const i in this.foodPropObj) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.foodPropObj.hasOwnProperty(i)) {
          money = new Decimal(money).add(
            new Decimal(
              Number(this.foodPropObj[i] ? this.foodPropObj[i].price : 0)
            )
          )
        }
      }
      money = new Decimal(money)
        .add(new Decimal(Number(this.goodItem.foodConcessionalPrice)))
        .toNumber()
      return money
    },
    // 计算原价
    getfoodOriginalPrice () {
      let money = 0
      if (Object.keys(this.foodPropObj).length === 0) {
        return Number(
          this.goodItem.foodOriginalPrice
            ? new Decimal(this.goodItem.foodOriginalPrice)
            : 0
        )
      }
      for (const i in this.foodPropObj) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.foodPropObj.hasOwnProperty(i)) {
          money = new Decimal(money).add(
            new Decimal(
              Number(this.foodPropObj[i] ? this.foodPropObj[i].price : 0)
            )
          )
        }
      }
      money = new Decimal(money)
        .add(new Decimal(Number(this.goodItem.foodOriginalPrice)))
        .toNumber()
      return money
    },
    // 显示规格
    getProp () {
      if (!this.foodPropObj || this.foodPropObj === null) {
        return ''
      }
      const ls = Object.values(this.foodPropObj).map((item) =>
        item ? item.name : ''
      )
      return ls.join('、')
    }
  },
  methods: {
    ...mapMutations({
      setCard: 'buyCard/setCard',
      reduceCard: 'buyCard/reduceCard'
    }),
    setCarAnmation (e) {
      this.setCard({
        shopId: this.shopInfo.id,
        ...this.goodItem,
        foodProp: JSON.stringify(this.foodPropObj),
        foodConcessionalPrice: this.getChenckMoney,
        foodOriginalPrice: this.getfoodOriginalPrice
      })
    },
    // 减少购物车
    reduce () {
      this.reduceCard({
        shopId: this.shopInfo.id,
        ...this.goodItem,
        foodProp: JSON.stringify(this.foodPropObj),
        foodConcessionalPrice: this.getChenckMoney,
        foodOriginalPrice: this.getfoodOriginalPrice
      })
    },
    // 设置选中样式
    setspecsItem (item, specsItem) {
      this.foodPropObj[item.name] = specsItem
    },
    // 计算价格
    getMoney (money) {
      return new Decimal(Number(this.goodItem.foodConcessionalPrice))
        .add(new Decimal(Number(money)))
        .toNumber()
    }
  }
}
</script>

<style lang="scss" scoped>
.foodProp {
  height: 600px;
  width: 400px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  &__title {
    font-weight: 700;
    font-size: 25px;
    padding: 20px 20px;
    box-sizing: border-box;
  }
  &__body {
    flex: 1;
    min-height: 0px;
    &--item {
      padding: 20px;
      box-sizing: border-box;
      &__title {
        font-size: 22px;
      }
      &__list {
        display: flex;
        flex-wrap: wrap;
        &--item {
          cursor: pointer;
          margin-top: 10px;
          display: flex;
          align-items: center;
          margin-right: 20px;
          width: auto;
          border: 1px solid rgb(224, 227, 233);
          border-radius: 8px;
          min-width: 90px;
          justify-content: center;
          &__name {
            font-size: 20px;
            width: auto;
            padding: 5px 15px;
          }
          &__money {
            font-size: 20px;
             position: relative;
            padding: 5px 15px;
          }
        }
      }
    }
  }
  &__bottom {
    padding: 30px 20px;
    box-shadow: 0px -5px 12px 0px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    &--top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      &__moeny {
        color: red;
        font-size: 25px;
      }
      &__button {
        cursor: pointer;
        background-color: #ffbb12;
        border-radius: 8px;
        width: 120px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        color: #000;
      }
    }
    &--prop {
      margin-top: 20px;
      color: rgba(16, 16, 16, 100);
    }
  }
}
::v-deep .el-scrollbar {
  overflow-y: hidden !important;
}
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
  overflow-y: auto !important;
}
::v-deep .is-horizontal {
  display: none;
}
.foodProp__body--item__list--item__money::after {
  position: absolute;
  height: 10px;
  width: 1px;
  background-color: rgb(224, 227, 233);
  content: "";
  left: 0.5px;
  top: 50%;
  margin-top: -5px;
}
.foodProp__body--item__list--item__active {
  border: 1px solid #ffbb12;
}

.foodItem__bottom--buy {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  .foodValue {
    margin-left: 20px;
    margin-right: 20px;
    font-size: 16px;
  }
  i {
    color: #ffbb12;
    font-size: 20px;
    cursor: pointer;
  }
}
</style>
