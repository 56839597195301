import request from '@/utils/request.js'

export default {
  // 保存
  foodInfoSave: (data) => {
    return request({
      url: '/business/foodInfo/save',
      data,
      method: 'post'
    })
  },
  // 查询
  foodInfoGetById: (id) => {
    return request({
      url: '/business/foodInfo/getById/' + id,
      method: 'post'
    })
  },
  // 是否上架
  foodInfoSaveSaleFlag: (data) => {
    return request({
      data,
      url: '/business/foodInfo/saveSaleFlag',
      method: 'post'
    })
  },
  // 删除菜品
  foodInfoDel: (id) => {
    return request({
      url: '/business/foodInfo/del/' + id,
      method: 'post'
    })
  },
  // 批量删除菜品
  delList: (data) => {
    return request({
      url: '/business/foodInfo/delList',
      data,
      method: 'post'
    })
  },
  // 保存菜品排序
  foodInfoSaveSort: (data) => {
    return request({
      url: '/business/foodInfo/saveSort',
      data,
      method: 'post'
    })
  }
}
