import request from '@/utils/request.js'

export default {
  // 保存
  foodTypeSave: (data) => {
    return request({
      url: '/business/foodType/save',
      data,
      method: 'post'
    })
  },
  // 删除
  foodTypeDel: (id) => {
    return request({
      url: '/business/foodType/del/' + id,
      method: 'post'
    })
  },
  // 排序
  foodTypeSaveSort: (data) => {
    return request({
      url: '/business/foodType/saveSort',
      data,
      method: 'post'
    })
  },
  // 根据商家id查询所有菜品类型
  listAllByBusinessId: (businessId) => {
    return request({
      url: '/business/foodType/listAllByBusinessId/' + businessId,
      method: 'post'
    })
  }
}
